.annonce {
  opacity: 0;
  width: 233px;
  height: 500px;
  background-color: #FF9318;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 1em;
  animation: annonce 2s forwards;
}
.annonce .image {
  background-color: #FF9318;
  background-repeat: no-repeat;
  height: 75%;
  background-size: cover;
}
.annonce .image:hover {
  transform: scaleY(1.02);
}
.annonce .infos {
  opacity: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 25%;
  animation: infos 1s 0.5s forwards;
}
.annonce .infos p {
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis ellipsis;
  width: 80%;
}
.annonce .infos .model {
  font-size: 12px;
}

@keyframes annonce {
  0% {
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes infos {
  0% {
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}/*# sourceMappingURL=style.css.map */