@font-face {
    font-family: 'Cookie';
    src: url('../../assets/cookie/Cookie-Regular.ttf');
  }
@font-face {
    font-family: 'Marko_one';
    src: url('../../assets/Marko_One/MarkoOne-Regular.ttf');
}

.PageObject{
    //AQUI VIENE TU ESTILO MI AMOL 
    .PageObject-header{
    position: relative;
    text-align: center;
    display: flex;
    align-items: flex-end;
    
        div{
            height: 36px;
            width: 100%;
            background-color: #FF9318;
            position:absolute;
            bottom: 24px;
        }
        h1{
            margin-bottom: -5px;
            font-size: 48px;
            z-index: 2;
            font-size: 85px;
            color: white;
            position:relative;
            margin-left: 50px;
        }
        h2{
            z-index: 2;
            font-size: 42px;
            margin-bottom: 13px;
            color: white;
            position:relative;
            margin-left: 50px;
        }
    }
.cadre2{
    position: relative;
    .ImageObjet{
        position: absolute;
        right: 5%;
        img{
            width: 100%;
            margin-top: 100px;
            border-radius: 5%;
        }
    }
    .RetourShop{
        margin-left: 140px;
        button{
           background-color: #FF9318;
           display: flex;
           align-items: center;
           font-family: 'Marko_one';
           border-radius: 5px;
           border-width: 0px;
           font-size: 20px;
           transition: all 0.2s;
           &:hover{
            background-color: #FFFFFF;
            color: black;
            transition: all 0.5s;
            border-radius: 15px;
            img{
                opacity: 0;
                transition: all 0.5s;
            }
        }
        }
        

            }
    .InfoObjet{
        color:white;
        margin-left: 140px;
        margin-top: 55px;
        font-size: 25px;
        p{
            margin: 10px;
        }
        .ÉtatOrange{
            color: #FF9318;
        }
    }
    .Description{
        background-color: #1E1E1E;
        display: flex;
        flex-direction: column;
        margin-left: 140px;
        p{
        color:#FF9318;
        font-family: 'Marko_one';
        font-size: 20px;
        margin-left: 140px;
        width: 40%;
        margin-top: 0px;
        }
        h3{
            color: white;
            font-size: 1.5em;
            margin-top: 1em;
        }
        .customTitle {
            color: white; /* Mettre en blanc les titres */
            font-size: 1.5em;
            margin-top: 1em;
        }
        
        .customParagraph {
            font-size: 1em;
            margin: 0.5em 0;
        }
        
        .customBold {
            font-weight: bold;
        }
        
        p span {
            color: inherit; /* Pour hériter la couleur définie dans le style inline */
        }
        
    }
    .Horaire{
        color: white;
        display: flex;
        align-items: center;
        margin-left: 140px;
        margin-top: 30px;
        font-size: 20px;
        .text{
            text-align: end;
            p{
                margin: 0px;
            }
        } 
    } 
    .horloge{
        margin-right: 20px; 
    }
    .TelephoneCadre{
        margin-top: 15px;
        .Telephone{
            display: flex;
            align-items: center;
            .IconTelephone{
                img{
                margin-left: 140px; 
                width:30px;
                height: 30px;
                }
            }
            .NumeroPhone{
                p{
                    color:#FF9318;
                    font-size: 30px;
                    margin:0px;
                    margin-left: 30px;
                }
            }
        }
    }
    .EmailCadre{
        margin-top: 15px;
        .Mail{
            display: flex;
            align-items: center;
            .IconMail{
                img{
                    margin-left: 140px; 
                    width:40px;
                    height:40px;
                    }
            }
            .DirectionMail{
                p{
                    color: white;
                    font-size: 20px;
                    margin:0px;
                    margin-left: 30px; 
                }
            }
            }
    }     
    .RueCadre{
        margin-top: 15px;
        .Rue{
            display: flex;
            align-items: center;
            .IconLieu{
                img{
                    margin-left: 140px; 
                    width:40px;
                    height:40px;
                    margin-bottom: 50px;
                }
            }
            .DirectionRue{
                p{
                    color: white;
                    font-size: 20px;
                    margin:0px;
                    margin-left: 30px;
                    margin-bottom: 50px;
                }
            }
        }
    }
    .LineGris{
        background-color:#1E1E1E;
        width: 100%;
        height: 60px;
    }
    .GuitareBasse{
        display: flex;
        justify-content: center;
        img{
            margin-top: 50px;
            margin-bottom: 50px;
            width: 130px;
        }
    }
}


    

}


@media screen and (max-width:820px) {
    //Aqui es como se va a comportar cuando estas une una pagina que hace menos de 870px.
    //Cuidado el style ese css, se anade al anterior ! Es decir diciendo nada tendra las particulidades anterior, y no las veras. 
    // Y te volveras loca.
    //Lo mejor es copiar todo el estilo aqui, y modificar todo.
    .PageObject{
        //AQUI VIENE TU ESTILO MI AMOL 
        .PageObject-header{
        position: relative;
        text-align: center;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        
            div{
                height: 36px;
                width: 100%;
                background-color: #FF9318;
                position:absolute;
                bottom: 24px;
            }
            h1{
                margin-bottom: -5px;
                font-size: 48px;
                z-index: 2;
                font-size: auto;
                color: white;
                position:relative;
                margin-left: 50px;
                width: 100%;
            }
            h2{
                z-index: 2;
                font-size: auto;
                margin-bottom: 13px;
                color: white;
                position:relative;
                margin-left: 0px;
                width: 100%;
            }
        }
    .cadre2{
        position: relative;
        .ImageObjet{
            position: relative;
            right: auto;
            display: flex;

            justify-content: center;

            img{
                width: 90%;
                margin-top: 100px;
            }
        }
        .RetourShop{
            position: absolute;
            top: 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            button{
               background-color: #FF9318;
               display: flex;
               align-items: center;
                justify-content: center;
               font-family: 'Marko_one';
               border-radius: 5px;
               border-width: 0px;
               font-size: 15px;
               width: 90%;
            }
                }
        .InfoObjet{
            color:white;
            margin-left: 0px;
            margin-top: 30px;
            font-size: 25px;
            p{
                margin: 10px;
                width: 90%;
                text-align: center;
            }
            .ÉtatOrange{
                color: #FF9318;
            }
        }
        .Description{
            background-color: #1E1E1E;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0px;
            p{
            color:#FF9318;
            font-size: 20px;
            margin-left: 10px;
            width: 90%;
            margin-top: 0px;
            }
        }
        .Horaire{
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 0px;
            margin-top: 30px;
            font-size: 20px;
            .text{
                text-align: end;
                p{
                    margin: 0px;
                }
            } 
        } 
        .horloge{
            margin-right: 0px; 
        }
        .TelephoneCadre{
            margin-top: 15px;
            .Telephone{
                display: flex;
                align-items: center;
                flex-direction: column;
                .IconTelephone{
                    img{
                    margin-left: 0px; 
                    width:30px;
                    height: auto;
                    }
                }
                .NumeroPhone{
                    p{
                        color:#FF9318;
                        font-size: 30px;
                        margin:0px;
                        margin-left: 0px;
                        
                    }
                    
                }
            }
        }
        .EmailCadre{
            margin-top: 15px;
            .Mail{
                display: flex;
                align-items: center;
                flex-direction: column;
                .IconMail{
                    img{
                        margin-left: 0px; 
                        width:40px;
                        height:auto;
                        }
                }
                .DirectionMail{
                    p{
                        color: white;
                        font-size: 20px;
                        margin:0px;
                        margin-left: 0px; 
                    }
                }
                }
        }     
        .RueCadre{
            margin-top: 15px;
            .Rue{
                display: flex;
                align-items: center;
                flex-direction: column;
                .IconLieu{
                    img{
                        margin-left: 0px; 
                        width:40px;
                        height:40px;
                        margin-bottom: 0px;
                    }
                }
                .DirectionRue{
                    p{
                        color: white;
                        font-size: 20px;
                        margin:0px;
                        margin-left: 0px;
                        margin-bottom: 50px;
                        text-align: center;
                    }
                }
            }
        }
        .LineGris{
            background-color:#1E1E1E;
            width: 100%;
            height: 60px;
        }
        .GuitareBasse{
            display: flex;
            justify-content: center;
            img{
                margin-top: 50px;
                margin-bottom: 50px;
                width: 25%;
            }
        }
    }
    
    
        
    
    }
    
}