@font-face {
    font-family: 'Cookie';
    src: url('./assets/cookie/Cookie-Regular.ttf');
}

@font-face {
    font-family: 'Marko_one';
    src: url('./assets/Marko_One/MarkoOne-Regular.ttf');
}

a {
    text-decoration: none;
}

.App {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 1100px;
}

.App-header {
    position: relative;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    font-size: calc(10px + 2vmin);
    color: white;
}

.orange-line {
    background-color: #FF9318;
    height: 25px;
    width: 100%;
    position: absolute;
    bottom: 23px;
    animation: lineOrange 2s forwards;
}

h1 {
    margin-bottom: -5px;
    font-size: 48px;
    z-index: 2;
    font-size: 85px;
    animation: ShopDisp 1s ;
}

h2 {
    opacity: 0;
    z-index: 2;
    font-size: 35px;
    margin-bottom: 13px;
    animation: vente 2s 1s forwards;
}

.cadre {
    display: flex;
    justify-content: space-between;
    background-image: url('./assets/images/cadre1/IMG_1661.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30% 25%;
    height: 505px;
    align-items: center;
    position: relative;
    padding-left: 2%;
    padding-right: 10%;
    margin-top: 20px;
}

.column1 {
    display: flex;
    flex-direction: column;
    opacity: 0;
    text-align: center;
    animation: colmn1 2s 3s forwards;
    align-self: flex-end;
}

.logoEcoleStore {
    width: 200px;
    margin-bottom: 15px;
    padding-left: 25%;
}

.telephone {
    display: flex;
    align-items: center;
}

.logoTel {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.numeroTel {
    font-size: 24px;
    color: #FF9318;
    margin-bottom: 18px;
}

.mail {
    display: flex;
    align-items: center;
}

.logoEnveloppe {
    padding-right: 5px;
    width: 30px;
    margin-right: 5px;
}

.directionMail {
    font-size: 16px;
    color: #ffffff;
}

.direction {
    display: flex;
    align-items: center;
}

.logoDirection {
    width: 20px;
    height: 20px;
    margin-right: 10px
}

.directionRue {
    font-size: 16px;
    color: #ffffff;
}

.column2 {
    text-align: center;
    position: absolute;
    left: 36%;
    top: 30%;
}

.DavidFucardName {
    opacity: 0;
    color: #FF9318;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 0px;
    animation: david 2s 2s forwards;
}

.Profession {
    opacity: 0;
    color: #ffffff;
    font-size: 15px;
    margin-top: 0px;
    animation: prof 2s 2s forwards;
}

.column3 {
    opacity: 0;
    text-align: center;
    animation: colmn3 2s 3s forwards;
    margin-right: 10px;
}
.column3 p{
    color: #ffffff;
}
.column3 .visite{
   
    display: flex;
    justify-content: center;
}
.column3 .visite img{
    width: 30px;
    margin-right: 10px;
    animation: visite 3s 4s infinite;

} 
.column3 .visite p{
    animation: visite 3s 4s infinite reverse;
}
.horaire{
    color:red;

}

.MontreDessin {
    width: 40px;
    margin-bottom: 35px;
    margin-right: 10px;
}

td{
    color: #ffffff;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: start;
    padding-right: 10px;
}
tr td:first-child{
    text-align: end;

}
.EcoleDeMusique{
    opacity: 0;
    margin-top: 100px;
    color: #FF9318;
    font-size: 25px;
    border: #ffffff 1px solid;
    margin-bottom: 100px;
    background-color: #1E1E1E;
    transition: 0.5s;
    cursor: 'pointer';
    user-select: none;
     text-overflow: clip;
    animation: ecoledeguitare 1s 3.5s forwards;
    white-space: nowrap;
    margin-left: auto;
    margin-right: auto;
   
}
.EcoleDeMusique:hover{
    background-color: #FF9318;
    color: #1E1E1E;
    transition: 0.5s;
    cursor: 'pointer';
    user-select: none;
}

.QuiDeMieux {
    opacity: 0;
    font-family: 'Marko';
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    animation: phrase 1s 5s forwards;
}

.filtres {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    background-color: #1E1E1E;
    padding: 20px;
}

.ToutesCategories {
    color: #000000;
    font-size: 15px;
    font-family: 'Marko_one';
    width: 220px;
    text-align: center;
    background-color: #FF9318;
    border: none;
    padding: 5px;
    border-radius: 3px;
}

.prix {
    color: #000000;
    font-size: 15px;
    font-family: 'Marko_one';
    text-align: center;
    background-color: #FF9318;
    width: 220px;
    border: none;
    padding: 5px;
    border-radius: 3px;
}

.guitaresEtAmplis {
    color: #ffffff;
    font-size: 1.2em;
}

.annonces {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.annonces div:hover{
    transform: scale(1.02);
    transition: 0.2s;
    cursor: 'pointer';
    user-select: none;
    
}
a{
    text-decoration: none;
    color: black;
}

@media screen and (max-width:870px) {
    /* //Aqui es como se va a comportar cuando estas une una pagina que hace menos de 870px.
  //Cuidado el style ese css, se anade al anterior ! Es decir diciendo nada tendra las particulidades anterior, y no las veras. 
  // Y te volveras loca.
  //Lo mejor es copiar todo el estilo aqui, y modificar todo. */
    .App {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 1100px;
    }
    .App-header {
        position: relative;
        background-color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    .orange-line {
        background-color: #FF9318;
        height: 25px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        animation: lineOrange 2s;
    }
    h1 {
        margin-bottom: 0px;
        font-size: 48px;
        z-index: 2;
        font-size: 85px;
    }
    h2 {
        z-index: 2;
        font-size: 35px;
        margin-bottom: 13px;
    }
    .cadre {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: url('./assets/images/cadre1/IMG_1661.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: top;
        background-position-x: 43%;
        height: 705px;
        align-items: center;
        position: relative;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 20px;
    }
    .column1 {
        text-align: center;
        padding-left: 0%;
        padding-top: 0%;
        width: 100%;

    }
    .logoEcoleStore {
        width: 240px;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .telephone {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .logoTel {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .numeroTel {
        font-size: 24px;
        color: #FF9318;
        margin-bottom: 18px;
    }
    .mail {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logoEnveloppe {
        width: 30px;
        margin-right: 5px;
    }
    .directionMail {
        font-size: 16px;
        color: #ffffff;
    }
    .direction {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logoDirection {
        width: 20px;
        height: 20px;
        margin-right: 10px
    }
    .directionRue {
        font-size: 14px;
        color: #ffffff;
    }
    .column2 {
        text-align: center;
        position: relative;
        left: 0%;
        top: 0%;
    }
    .DavidFucardName {
        text-align: center;

        color: #FF9318;
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .Profession {
        color: #ffffff;
        font-size: 18px;
        margin-top: 0px;
    }
    .column3 {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .MontreDessin {
        margin-right: 0px;
        margin-bottom: 10px;
        width: 40px;
    }
    .QuiDeMieux {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .filtres {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: #1E1E1E;
        padding: 20px;
    }
    .ToutesCategories {
        color: #000000;
        font-size: 15px;
        font-family: 'Marko_one';
        width: 220px;
        text-align: center;
        background-color: #FF9318;
        margin-bottom: 30px;
    }
    .prix {
        color: #000000;
        font-size: 15px;
        font-family: 'Marko_one';
        text-align: center;
        background-color: #FF9318;
        width: 220px;
    }
    .guitaresEtAmplis {
        color: #ffffff;
        font-size: 1em;
    }
    .annonces {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}


@keyframes lineOrange {
    0%{
        width: 0px;
    }
    100%{
        width: 100%;
    }
    
}
@keyframes ShopDisp {
    0%{
        transform: translate(-50px);
        opacity: 0;
    }
    100%{
        transform: translate(0px);
        opacity: 1;
    }
    
}
@keyframes vente {
    0%{
        transform: translateY(-50px);
        opacity: 0;
    }
    100%{
        transform: translate(0px);
        opacity: 1;
    }
    
}
@keyframes colmn1 {
    0%{
        transform: translateX(-20px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
    
}
@keyframes david {
    0%{
        transform: scale(2);
        opacity: 0;
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
    
}
@keyframes prof {
    0%{
        transform: translateY(-20px);
        opacity: 0;
    }
    100%{
        transform: translateY(0px);
        opacity: 1;
    }
    
}
@keyframes colmn3 {
    0%{
        transform: translateX(20px);
    }
    100%{
        opacity: 1;
        transform: translateX(-10px);
    }
}
@keyframes phrase {
    0%{
        transform: translateY(20px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
    
}
@keyframes ecoledeguitare {
    0%{
        width: 0%;
    }
    100%{
        opacity: 1;
        width: 100%;
    }
    
    
}
@keyframes visite {
    0%{
        transform: scale(1);
        rotate: 0deg;
    }
    20%{
        transform: scale(1.2);
        rotate: 10deg;
    }
    40%{
        transform: scale(1);
        rotate: 0deg;
    }
    
}
