@font-face {
  font-family: 'Marko';
  src: url('./assets/Marko_One/MarkoOne-Regular.ttf');
}


body {
  
  margin-left: auto;
  margin-right: auto;
  
  background-color: black;
  font-family: 'Marko';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
