@charset "UTF-8";
@font-face {
  font-family: "Cookie";
  src: url("../../assets/cookie/Cookie-Regular.ttf");
}
@font-face {
  font-family: "Marko_one";
  src: url("../../assets/Marko_One/MarkoOne-Regular.ttf");
}
.PageObject .PageObject-header {
  position: relative;
  text-align: center;
  display: flex;
  align-items: flex-end;
}
.PageObject .PageObject-header div {
  height: 36px;
  width: 100%;
  background-color: #FF9318;
  position: absolute;
  bottom: 24px;
}
.PageObject .PageObject-header h1 {
  margin-bottom: -5px;
  font-size: 48px;
  z-index: 2;
  font-size: 85px;
  color: white;
  position: relative;
  margin-left: 50px;
}
.PageObject .PageObject-header h2 {
  z-index: 2;
  font-size: 42px;
  margin-bottom: 13px;
  color: white;
  position: relative;
  margin-left: 50px;
}
.PageObject .cadre2 {
  position: relative;
}
.PageObject .cadre2 .ImageObjet {
  position: absolute;
  right: 5%;
}
.PageObject .cadre2 .ImageObjet img {
  width: 100%;
  margin-top: 100px;
  border-radius: 5%;
}
.PageObject .cadre2 .RetourShop {
  margin-left: 140px;
}
.PageObject .cadre2 .RetourShop button {
  background-color: #FF9318;
  display: flex;
  align-items: center;
  font-family: "Marko_one";
  border-radius: 5px;
  border-width: 0px;
  font-size: 20px;
  transition: all 0.2s;
}
.PageObject .cadre2 .RetourShop button:hover {
  background-color: #FFFFFF;
  color: black;
  transition: all 0.5s;
  border-radius: 15px;
}
.PageObject .cadre2 .RetourShop button:hover img {
  opacity: 0;
  transition: all 0.5s;
}
.PageObject .cadre2 .InfoObjet {
  color: white;
  margin-left: 140px;
  margin-top: 55px;
  font-size: 25px;
}
.PageObject .cadre2 .InfoObjet p {
  margin: 10px;
}
.PageObject .cadre2 .InfoObjet .ÉtatOrange {
  color: #FF9318;
}
.PageObject .cadre2 .Description {
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  margin-left: 140px;
}
.PageObject .cadre2 .Description p {
  color: #FF9318;
  font-family: "Marko_one";
  font-size: 20px;
  margin-left: 140px;
  width: 40%;
  margin-top: 0px;
}
.PageObject .cadre2 .Description h3 {
  color: white;
  font-size: 1.5em;
  margin-top: 1em;
}
.PageObject .cadre2 .Description .customTitle {
  color: white; /* Mettre en blanc les titres */
  font-size: 1.5em;
  margin-top: 1em;
}
.PageObject .cadre2 .Description .customParagraph {
  font-size: 1em;
  margin: 0.5em 0;
}
.PageObject .cadre2 .Description .customBold {
  font-weight: bold;
}
.PageObject .cadre2 .Description p span {
  color: inherit; /* Pour hériter la couleur définie dans le style inline */
}
.PageObject .cadre2 .Horaire {
  color: white;
  display: flex;
  align-items: center;
  margin-left: 140px;
  margin-top: 30px;
  font-size: 20px;
}
.PageObject .cadre2 .Horaire .text {
  text-align: end;
}
.PageObject .cadre2 .Horaire .text p {
  margin: 0px;
}
.PageObject .cadre2 .horloge {
  margin-right: 20px;
}
.PageObject .cadre2 .TelephoneCadre {
  margin-top: 15px;
}
.PageObject .cadre2 .TelephoneCadre .Telephone {
  display: flex;
  align-items: center;
}
.PageObject .cadre2 .TelephoneCadre .Telephone .IconTelephone img {
  margin-left: 140px;
  width: 30px;
  height: 30px;
}
.PageObject .cadre2 .TelephoneCadre .Telephone .NumeroPhone p {
  color: #FF9318;
  font-size: 30px;
  margin: 0px;
  margin-left: 30px;
}
.PageObject .cadre2 .EmailCadre {
  margin-top: 15px;
}
.PageObject .cadre2 .EmailCadre .Mail {
  display: flex;
  align-items: center;
}
.PageObject .cadre2 .EmailCadre .Mail .IconMail img {
  margin-left: 140px;
  width: 40px;
  height: 40px;
}
.PageObject .cadre2 .EmailCadre .Mail .DirectionMail p {
  color: white;
  font-size: 20px;
  margin: 0px;
  margin-left: 30px;
}
.PageObject .cadre2 .RueCadre {
  margin-top: 15px;
}
.PageObject .cadre2 .RueCadre .Rue {
  display: flex;
  align-items: center;
}
.PageObject .cadre2 .RueCadre .Rue .IconLieu img {
  margin-left: 140px;
  width: 40px;
  height: 40px;
  margin-bottom: 50px;
}
.PageObject .cadre2 .RueCadre .Rue .DirectionRue p {
  color: white;
  font-size: 20px;
  margin: 0px;
  margin-left: 30px;
  margin-bottom: 50px;
}
.PageObject .cadre2 .LineGris {
  background-color: #1E1E1E;
  width: 100%;
  height: 60px;
}
.PageObject .cadre2 .GuitareBasse {
  display: flex;
  justify-content: center;
}
.PageObject .cadre2 .GuitareBasse img {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 130px;
}

@media screen and (max-width: 820px) {
  .PageObject .PageObject-header {
    position: relative;
    text-align: center;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .PageObject .PageObject-header div {
    height: 36px;
    width: 100%;
    background-color: #FF9318;
    position: absolute;
    bottom: 24px;
  }
  .PageObject .PageObject-header h1 {
    margin-bottom: -5px;
    font-size: 48px;
    z-index: 2;
    font-size: auto;
    color: white;
    position: relative;
    margin-left: 50px;
    width: 100%;
  }
  .PageObject .PageObject-header h2 {
    z-index: 2;
    font-size: auto;
    margin-bottom: 13px;
    color: white;
    position: relative;
    margin-left: 0px;
    width: 100%;
  }
  .PageObject .cadre2 {
    position: relative;
  }
  .PageObject .cadre2 .ImageObjet {
    position: relative;
    right: auto;
    display: flex;
    justify-content: center;
  }
  .PageObject .cadre2 .ImageObjet img {
    width: 90%;
    margin-top: 100px;
  }
  .PageObject .cadre2 .RetourShop {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .PageObject .cadre2 .RetourShop button {
    background-color: #FF9318;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Marko_one";
    border-radius: 5px;
    border-width: 0px;
    font-size: 15px;
    width: 90%;
  }
  .PageObject .cadre2 .InfoObjet {
    color: white;
    margin-left: 0px;
    margin-top: 30px;
    font-size: 25px;
  }
  .PageObject .cadre2 .InfoObjet p {
    margin: 10px;
    width: 90%;
    text-align: center;
  }
  .PageObject .cadre2 .InfoObjet .ÉtatOrange {
    color: #FF9318;
  }
  .PageObject .cadre2 .Description {
    background-color: #1E1E1E;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
  .PageObject .cadre2 .Description p {
    color: #FF9318;
    font-size: 20px;
    margin-left: 10px;
    width: 90%;
    margin-top: 0px;
  }
  .PageObject .cadre2 .Horaire {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    margin-top: 30px;
    font-size: 20px;
  }
  .PageObject .cadre2 .Horaire .text {
    text-align: end;
  }
  .PageObject .cadre2 .Horaire .text p {
    margin: 0px;
  }
  .PageObject .cadre2 .horloge {
    margin-right: 0px;
  }
  .PageObject .cadre2 .TelephoneCadre {
    margin-top: 15px;
  }
  .PageObject .cadre2 .TelephoneCadre .Telephone {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .PageObject .cadre2 .TelephoneCadre .Telephone .IconTelephone img {
    margin-left: 0px;
    width: 30px;
    height: auto;
  }
  .PageObject .cadre2 .TelephoneCadre .Telephone .NumeroPhone p {
    color: #FF9318;
    font-size: 30px;
    margin: 0px;
    margin-left: 0px;
  }
  .PageObject .cadre2 .EmailCadre {
    margin-top: 15px;
  }
  .PageObject .cadre2 .EmailCadre .Mail {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .PageObject .cadre2 .EmailCadre .Mail .IconMail img {
    margin-left: 0px;
    width: 40px;
    height: auto;
  }
  .PageObject .cadre2 .EmailCadre .Mail .DirectionMail p {
    color: white;
    font-size: 20px;
    margin: 0px;
    margin-left: 0px;
  }
  .PageObject .cadre2 .RueCadre {
    margin-top: 15px;
  }
  .PageObject .cadre2 .RueCadre .Rue {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .PageObject .cadre2 .RueCadre .Rue .IconLieu img {
    margin-left: 0px;
    width: 40px;
    height: 40px;
    margin-bottom: 0px;
  }
  .PageObject .cadre2 .RueCadre .Rue .DirectionRue p {
    color: white;
    font-size: 20px;
    margin: 0px;
    margin-left: 0px;
    margin-bottom: 50px;
    text-align: center;
  }
  .PageObject .cadre2 .LineGris {
    background-color: #1E1E1E;
    width: 100%;
    height: 60px;
  }
  .PageObject .cadre2 .GuitareBasse {
    display: flex;
    justify-content: center;
  }
  .PageObject .cadre2 .GuitareBasse img {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 25%;
  }
}/*# sourceMappingURL=style.css.map */